import React, { useEffect } from 'react'

import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.grayDark};
    font-family: 'Raleway', sans-serif;
    margin: 0;
    position: relative;
    min-height: 80vh;
    @media print {
        background: white;
    }
  }
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::selection {
      background-color: ${props => props.theme.grayGreen}
  }
`

const Main = styled.main``

export const theme = {
    lightBlue: '#f0f5f9',
    gray1: '#535c68',
    gray2: '#f4f7f7',
    gray3: '#bec8d0',
    gray4: '#71787d',
    grayDark: '#292e33',
    red: '#e64d4d',
    blue: '#1231dc',
    gold: '#ffbf00',
    green: '#4caf50',
    black: 'black',
    grayGreen: '#ebf5ee',
    footerHeight: '120px',
    breakpoints: {
        sm: '48em',
        md: '62em',
        lg: '75em',
    },
    sm: '(min-width: 48em)',
    md: '(min-width: 62em)',
    lg: '(min-width: 75em)',
}

theme.backgroundColor = theme.lightBlue

const Layout = ({ children }) => {
    useEffect(() => {
        const setWindowHeight = () => {
            let vh = window.innerHeight * 0.01
            document.body.style.setProperty('--vh', `${vh}px`)
        }

        setWindowHeight()

        window.addEventListener('resize', setWindowHeight)

        return () => {
            window.removeEventListener('resize', setWindowHeight)
        }
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <Main onTouchStart={() => {}}>
                <GlobalStyle />

                {children}
            </Main>
        </ThemeProvider>
    )
}

export default Layout
