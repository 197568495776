import React, { useState } from 'react'

import styled, { keyframes, css } from 'styled-components'

import { GOLDEN } from './styles'

import { useSpring, animated } from 'react-spring'

import { useGesture } from 'react-with-gesture'

import { FaSpinner } from 'react-icons/fa'

import { Link } from 'gatsby'

import { theme } from './layout'

const buttonStyle = css`
    position: relative;
    display: inline-block;
    -webkit-appearance: none;
    user-select: none;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 1px;
    background: none;
    border: 0;
    outline: 0;
    padding: 0.6rem ${0.6 * GOLDEN}rem;
    background-color: ${theme.gray1};
    border-radius: 3px;
    color: white;
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
`

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(FaSpinner)`
    width: 20px;
    height: 20px;
    animation: ${spin} 2s linear infinite;
    position: absolute;
    right: -25px;
    top: 5px;
    color: ${props => props.theme.gray1};
`

const AnimatedLink = animated(Link)

function Button({ children, ...props }) {
    if (props.href)
        return (
            <animated.a href={props.href} css={buttonStyle} {...props}>
                {children}
            </animated.a>
        )
    if (props.to) {
        return (
            <AnimatedLink to={props.to} css={buttonStyle} {...props}>
                {children}
            </AnimatedLink>
        )
    }
    return (
        <animated.button css={buttonStyle} {...props}>
            {children}
        </animated.button>
    )
}

export default ({ children, loading, disabled, ...props }) => {
    const [bind, { down }] = useGesture()
    const [hover, setHover] = useState(false)

    const { opacity, shadow, translate } = useSpring({
        shadow: hover && !down ? 5 : 0.6,
        opacity: disabled || loading ? 0.5 : 1,
        translate: down ? 2 : 0,
    })

    return (
        <Button
            {...bind()}
            style={{
                boxShadow: shadow.interpolate(
                    s =>
                        `${s}px ${s}px ${s * 2}px ${s /
                            3}px rgba(25, 65, 97, 0.5)`
                ),
                opacity,
                transform: translate.interpolate(t => `translateY(${t}px)`),
            }}
            disabled={loading || disabled}
            onMouseMove={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...props}
        >
            {loading && <Spinner />}
            {children}
        </Button>
    )
}
