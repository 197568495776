import { useContext, useEffect, useState } from 'react'

import countries from '../constants/country-codes.json'

import { AppContext } from '../components/app-store'

import axios from 'axios'

import { isEmpty } from 'lodash-es'

import pathToRegExp from 'path-to-regexp'

import { MY_PROFILE } from '../components/data-provider'

import { useQuery } from '@apollo/react-hooks'

let loadingCountry = false
let countryIsLoaded = false
export const useGeoCountry = () => {
    const [state, set] = useContext(AppContext)

    const setCountry = country => set({ ...state, country })

    useEffect(() => {
        if (!countryIsLoaded) {
            const country = countries.find(c => c.code === 'AE')
            setCountry(country)
	    countryIsLoaded = true
        }
        // eslint-disable-next-line
    }, [])

    return [state.country]
}

export const useBusinessCardSlug = () => {
    const [slug, setSlug] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let expression = pathToRegExp('/bc/:slug')

            let result = expression.exec(window.location.pathname)
            // ['/hello/world', 'hello', 'world']

            if (result && result[1]) {
                setSlug(result[1])
            }
        }
    }, [])

    return [slug]
}

export const useEmailSignatureSlug = () => {
    const [slug, setSlug] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let expression = pathToRegExp('/email-signature/:slug')

            let result = expression.exec(window.location.pathname)
            // ['/hello/world', 'hello', 'world']

            if (result && result[1]) {
                setSlug(result[1])
            }
        }
    }, [])

    return [slug]
}

export const useActivationCode = () => {
    const [activationCode, setActivationCode] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let expression = pathToRegExp('/activate/:activationCode')

            let result = expression.exec(window.location.pathname)
            // ['/hello/world', 'hello', 'world']

            if (result && result[1]) {
                setActivationCode(result[1])
            }
        }
    }, [])

    return [activationCode]
}

export const usePasswordResetCode = () => {
    const [code, setCode] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let expression = pathToRegExp('/change-password/:code')

            let result = expression.exec(window.location.pathname)
            // ['/hello/world', 'hello', 'world']

            if (result && result[1]) {
                setCode(result[1])
            }
        }
    }, [])

    return [code]
}

export const useCurrentUser = () => {
    const [state, set] = useContext(AppContext)

    const setUser = user => set({ ...state, user })

    const { data, loading } = useQuery(MY_PROFILE)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (data && !loading && !data.myProfile) {
                // token is invalid
                window.localStorage.removeItem('token')
            } else if (data && data.myProfile) {
                setUser(data.myProfile)
            }
        }
        // eslint-disable-next-line
    }, [data, loading])

    return [state.user]
}

export const useLogout = () => {
    const [state, set] = useContext(AppContext)

    const setUser = user => set({ ...state, user })

    return () => {
        if (typeof window !== 'undefined') {
            setUser(null)
            window.localStorage.removeItem('token')
            window.location = '/'
        }
    }
}
