import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { Link } from 'gatsby'

import VerificoLogoImage from '../images/logo.svg'

import { GOLDEN_I, GOLDEN, Container } from './styles'

import Portal from './portal'

import { IoIosCloseCircle } from 'react-icons/io'

import { useTransition, animated } from 'react-spring'

import { useBusinessCardSlug } from '../hooks'

import { useQuery } from '@apollo/react-hooks'

import { GET_BUSINESS_CARD_BY_SLUG } from './data-provider'

import { useCurrentUser } from '../hooks'

import { get } from 'lodash-es'

import { API_URL } from './input'

import CroppedImage from './cropped-image'

const VerificoLogo = styled(VerificoLogoImage)`
    height: 20px;
`

const MenuModal = animated(styled.div`
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    margin: 0.5rem;
    right: 0;
    z-index: 10000;
    padding: 1rem;
    border-radius: 1px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    will-change: margin, opacity;
`)

const Toolbar = styled.div`
    top: 0;
    right: 0;
    left: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    ${'' /* justify-content: center; */}
    flex-wrap: nowrap;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
        justify-content: center;
    }
    @media print {
        display: none;
    }
`

const CompanyName = styled.h2`
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 0.9rem;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const Header = styled.header`
    background-color: ${props => props.theme.lightBlue};
    padding: 1rem 0;
`

const Logo = styled(CroppedImage)`
    height: auto;
    max-height: 60px;
    width: 80px;
    object-fit: contain;
`

const MenuButton = styled.h2`
    font-size: 0;
    background-color: currentColor;
    border-radius: 1px;
    height: 3px;
    width: 1.5rem;
    position: relative;
    margin: 0;

    &:before {
        background-color: currentColor;
        border-radius: 1px;
        height: 3px;
        width: 1.5rem;
        left: 0;
        position: absolute;
        content: '';
        top: -0.35rem;
    }

    &:after {
        background-color: currentColor;
        border-radius: 1px;
        height: 3px;
        width: 1.5rem;
        left: 0;
        position: absolute;
        content: '';
        top: 0.4rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
        display: none;
    }
`

const CloseButton = styled(IoIosCloseCircle)`
    width: 2rem;
    height: 2rem;
    display: block;
    margin-left: auto;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const MobileLinks = styled.div`
    display: flex;
    flex-direction: column;
`

const MobileLink = styled(Link)`
    display: block;
    padding: 0.5rem;
    font-size: ${GOLDEN_I}rem;
    background-color: ${props => props.theme.gray2};
    margin-bottom: 0.5rem;
    color: ${props => props.theme.blue};
    font-weight: 700;
    letter-spacing: 1px;
    &:first-child {
        margin-top: 0.5rem;
    }
    &:last-child {
        margin-bottom: 0;
    }
`

const Navigation = styled.h3`
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    color: ${props => props.theme.gray3};
`

const DesktopLinks = styled.div`
    padding: 0;
    margin: 0;
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        display: flex;
    }
`

const DesktopLink = styled(Link)`
    display: none;
    padding: 0.5rem ${GOLDEN}rem;
    font-size: 0.8rem;

    margin-bottom: 0.5rem;
    color: ${props => props.theme.blue};
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    &:last-child {
        padding-right: 0;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        display: block;
    }
`

const initialMenu = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'Get Verified',
        link: '/verify-your-business',
    },
    {
        title: 'Company',
        link: '/company',
    },
    {
        title: 'Pricing',
        link: '/pricing',
    },
    {
        title: 'Support',
        link: '/support',
    },
    {
        title: 'Login',
        link: '/login',
    },
]

const SiteHeader = () => {
    const [items, setItems] = useState([])
    const [menu, setMenu] = useState(initialMenu)

    const [user] = useCurrentUser()

    useEffect(() => {
        if (user) {
            const newMenu = initialMenu.filter(i => i.link !== '/login')
            setMenu([
                ...newMenu,
                {
                    title: 'Personal Area',
                    link: '/personal-area',
                },
                {
                    title: 'Logout',
                    link: '/logout',
                },
            ])
        }
    }, [user])

    const setMenuOpen = isOpen => {
        isOpen ? setItems([1]) : setItems([])
    }

    const transition = useTransition(items, i => i, {
        from: {
            opacity: 0,
            margin: '0rem',
        },
        enter: {
            opacity: 1,
            margin: '0.5rem',
        },
        leave: {
            opacity: 0,
            margin: '0rem',
        },
    })

    return (
        <Header>
            <Container>
                <Row>
                    <Link to="/">
                        <VerificoLogo />
                    </Link>

                    <MenuButton onClick={() => setMenuOpen(true)}>
                        Menu
                    </MenuButton>

                    <DesktopLinks>
                        {menu.map(item => {
                            return (
                                <DesktopLink to={item.link} key={item.title}>
                                    {item.title}
                                </DesktopLink>
                            )
                        })}
                    </DesktopLinks>

                    {transition.map(({ item, props, key }) => {
                        return (
                            <Portal id="mobile-menu">
                                <MenuModal style={props}>
                                    <Row>
                                        <Navigation>Navigation</Navigation>
                                        <CloseButton
                                            onClick={() => setMenuOpen(false)}
                                        />
                                    </Row>

                                    <MobileLinks>
                                        {menu.map(item => {
                                            return (
                                                <MobileLink
                                                    key={item.title}
                                                    to={item.link}
                                                >
                                                    {item.title}
                                                </MobileLink>
                                            )
                                        })}
                                    </MobileLinks>
                                </MenuModal>
                            </Portal>
                        )
                    })}
                </Row>
            </Container>
        </Header>
    )
}
const CompanyHeader = () => {
    const [slug] = useBusinessCardSlug()

    // eslint-disable-next-line
    const { loading, error, data } = useQuery(GET_BUSINESS_CARD_BY_SLUG, {
        variables: { slug },
    })

    const name = get(data, 'businessCard.organizationName')

    const active = get(data, 'businessCard.active')

    const logo = get(data, 'businessCard.companyLogo.url')

    return name ? (
        <Toolbar>
            {logo && (
                <Logo
                    src={API_URL + logo}
                    crop={get(data, 'businessCard.companyLogo.crop')}
                />
            )}
            {name && <CompanyName>{name}</CompanyName>}
        </Toolbar>
    ) : null
}

export default ({ mode }) => {
    if (mode === 'company-header') {
        return <CompanyHeader />
    }
    if (mode === 'site-header') {
        return <SiteHeader />
    }
}
