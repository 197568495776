import React from 'react'

import styled from 'styled-components'

const NormalImage = styled.img`
    object-fit: cover;
`

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => (100 / props.crop.width) * 100}%;
    height: auto;
    transform: translateX(${props => -props.crop.x}%)
        translateY(${props => -props.crop.y}%);
`

const Container = styled.div`
    padding-bottom: ${props => props.crop.aspect * 100}%;
    position: relative;
    overflow: hidden;
    border-radius: ${props => (props.circle ? 50 : 0)}%;
    width: 100%;
    height: 100%;
`

const Wrapper = styled.div`
    position: relative;
`

export default ({ alt, src, crop = {}, circle, className }) => {
    return crop ? (
        <Wrapper className={className}>
            <Container crop={crop} circle={circle}>
                <Image src={src} crop={crop} alt={alt} />
            </Container>
        </Wrapper>
    ) : (
        <NormalImage src={src} circle className={className} alt={alt} />
    )
}
