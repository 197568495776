import { Link as GatsbyLink } from 'gatsby'

import styled, { keyframes } from 'styled-components'

import { FaSpinner } from 'react-icons/fa'

// Golden ratio love!
export const GOLDEN = 1.61803398875

export const GOLDEN_I = 1 / GOLDEN

export const Input = styled.input`
    display: block;
    width: 100%;
    -webkit-appearance: none;

    font-family: inherit;
    outline: 0;
    padding: 0.5rem ${GOLDEN * 0.5}rem 0.5rem
        ${props => (props.type === 'search' ? '30px' : GOLDEN * 0.5 + 'rem')};
    border-radius: ${props => (props.type === 'search' ? 25 : 5)}px;
    border: 1px solid ${props => props.theme.gray3};
    border-color: ${props =>
        props.hasError ? props.theme.red : props.theme.gray3};
    font-size: 1rem;
    box-shadow: 0 0 1px 2px hsla(0, 0%, 93%, 0.52);
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    &:focus {
        border-color: ${props => props.theme.grayDark};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        transition-delay: 10000s;
    }
`

export const Link = styled(GatsbyLink)`
    color: ${props => props.theme.blue};
    display: ${props => (props.inline ? 'inline' : 'block')};
    text-align: ${props => (props.center ? 'center' : 'inherite')};
`

export const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 0.5rem;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 700px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        max-width: 900px;
    }
`
export const Angle = styled.div`
    position: absolute;
    display: block;
    content: '';
    height: 4rem;
    left: 0;
    right: 0;
    bottom: -2rem;
    background-color: ${props =>
        props.backgroundColor || props.theme.backgroundColor};
    transform: skewY(-4deg);
    z-index: 0;
    border-bottom: 2px solid #bec8d0;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
        transform: skewY(-2deg);
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
        bottom: -2.5rem;
    }
`

export const Section = styled.section`
    background-color: ${props =>
        props.alternate ? props.theme.grayGreen : 'transparent'};
    padding: 2rem 1rem 0 1rem;
    text-align: ${props => (props.left ? 'left' : 'center')};
    position: relative;
    z-index: ${props => props.angle && 100};
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        text-align: left;

        padding-bottom: ${props => (props.last ? '2rem' : '0')};
    }
`

export const SectionTitle = styled.h2`
    font-weight: 400;
    margin: 1rem 0;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled(FaSpinner)`
    font-size: 4rem;
    animation: ${spin} 1s linear infinite;
`
