import React, { useState, useEffect, useRef } from 'react'

import { Input } from './styles'

import styled, { keyframes } from 'styled-components'

import { FaSearch, FaImage } from 'react-icons/fa'

import { MdCheckBox } from 'react-icons/md'

import { useGeoCountry } from '../hooks'

import countries from '../constants/country-codes'

import { isEmpty, get } from 'lodash-es'

import { FaSpinner } from 'react-icons/fa'

import { GET_FILE, SINGLE_UPLOAD } from './data-provider'

import { useQuery, useMutation } from '@apollo/react-hooks'

import uuid from 'uuid/v1'

import Button from './button'

import CropModal from './crop-modal'

import CroppedImage from './cropped-image'

export const API_URL = process.env.GATSBY_API_ROOT

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SearchIcon = styled(FaSearch)`
    position: absolute;
    top: 10px;
    left: 10px;
`
const Container = styled.div`
    position: relative;
    margin: 1rem 0;
`

export const Label = styled.label`
    position: relative;
    animation: ${fadeIn} ease 0.2s backwards;
    font-weight: 500;
    color: ${props => props.theme.gray1};
    margin-bottom: 0.5rem;
    display: block;
    user-select: none;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const FileLabel = styled.div`
    font-size: 0;
    user-select: none;
    position: relative;
    display: inline-block;
    margin-top: 1rem;
`

const Comment = styled.div`
    font-size: 0.8rem;
    margin-top: 1rem;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const InputContainer = styled.div`
    position: relative;
    font-size: 0;
`

const CountrySelectorContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.gray2};
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    bottom: 0.5rem;
    padding: 0 0.5rem;
    align-items: center;
    border-radius: 1px;
    font-size: 0.6rem;
`

const CountryName = styled.div`
    width: 1rem;
    overflow: hidden;
    white-space: nowrap;
`
const DialNumber = styled.div`
    width: 1.8rem;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
`

const CountrySelector = styled.select`
    -webkit-appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
`

const spin = keyframes`
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
`

const Spinner = styled(FaSpinner)`
    width: 15px;
    height: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    animation: ${spin} 1s linear infinite;
`

const PhoneInput = styled(Input)`
    padding-left: calc(70px + 0.5rem);
`

const FileInputElem = styled(Input)`
    display: none;
`

const Image = styled(FaImage)`
    font-size: 4rem;
    width: auto;
    padding-left: 0;
    color: ${props => props.theme.gray4};
`

const Preview = styled(CroppedImage)`
    height: 110px;
    width: 110px;
    object-fit: cover;
    object-position: center;
    background-color: white;
    border-radius: ${props => (props.circle ? '50%' : 0)};
    padding: ${props => (props.circle ? '1rem' : '0.5rem')};
    border: 1px solid ${props => props.theme.gray3};
`

const ImageMask = styled.div`
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.transparent ? 'transparent' : 'white')};
    border: ${props =>
        props.noBorder ? '0' : `1px solid ${props.theme.gray3}`};
    border-radius: ${props => (props.circle ? '50%' : '5px')};
    opacity: ${props => (props.loading ? 0.3 : 1)};
`

const UploadedMessage = styled.div`
    opacity: ${props => (props.uploaded ? 1 : 0)};
    transition: opacity 0.5s ease;
    position: absolute;
    width: 200px;
    left: 100%;
    margin-left: 0.5rem;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    color: ${props => props.theme.gray1};
    font-size: 0.8rem;
`

const Check = styled(MdCheckBox)`
    margin-right: 0;
`

const Error = styled.p`
    color: ${props => props.theme.red};
    font-size: 0.5rem;
    opacity: ${props => (props.disabled ? 0.3 : 1)};
    position: absolute;
    bottom: -1.25rem;
    text-transform: lowercase;
`

const FileActionsRow = styled.div`
    display: flex;
    margin-top: 1rem;
`

const CountryPicker = ({ onChange = () => {} }) => {
    const [geoCountry] = useGeoCountry()

    const [country, setCountry] = useState({})

    const updateCountry = country => {
        setCountry(country)
        onChange({
            target: { value: country.dial_code.replace(/ /g, '') },
        })
    }

    useEffect(() => {
        if (isEmpty(country) && !isEmpty(geoCountry)) {
            updateCountry(geoCountry)
        }
        // eslint-disable-next-line
    }, [geoCountry])

    return (
        <CountrySelectorContainer>
            {isEmpty(country) && <Spinner />}
            <CountryName>{country.code}</CountryName>
            <DialNumber>{country.dial_code}</DialNumber>
            <CountrySelector
                onChange={e => {
                    const country = countries.find(
                        c => c.code === e.target.value
                    )
                    updateCountry(country)
                }}
                value={country.code}
            >
                {countries.map(c => {
                    return (
                        <option key={c.code} value={c.code}>
                            {c.name} {c.dial_code}
                        </option>
                    )
                })}
            </CountrySelector>
        </CountrySelectorContainer>
    )
}

const FileInput = ({ freeCrop, value, ...props }) => {
    const [imageURL, setImageURL] = useState('')
    const inputRef = useRef({})

    const [cropModalOpen, setCropModalOpen] = useState(false)

    const [crop, setCrop] = useState({
        aspect: 1,
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    })

    const [singleUpload, { data, loading }] = useMutation(SINGLE_UPLOAD)

    const { data: fileData } = useQuery(GET_FILE, {
        variables: {
            id: value,
        },
    })

    const reset = () => {
        setImageURL('')

        props.onChange({
            target: { value: null },
        })
    }

    useEffect(() => {
        if (fileData && fileData.file) {
            setImageURL(API_URL + fileData.file.url)
        }
    }, [fileData])

    useEffect(() => {
        if (!imageURL || imageURL.match('data')) return
    }, [imageURL])

    useEffect(() => {
        const singleUpload = get(data, 'singleUpload')

        if (!isEmpty(singleUpload)) {
            props.onChange({
                target: { value: singleUpload._id, type: 'file' },
            })
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <>
            <FileLabel>
                {loading && <Spinner />}
                <UploadedMessage
                    uploaded={
                        imageURL &&
                        data &&
                        data.singleUpload &&
                        data.singleUpload._id
                            ? 1
                            : 0
                    }
                >
                    <Check /> Uploaded
                </UploadedMessage>
                <ImageMask
                    loading={loading ? 1 : 0}
                    noBorder={imageURL ? 1 : 0}
                    circle={props.circle ? 1 : 0}
                >
                    {!imageURL && <Image />}
                    {imageURL && (
                        <Preview
                            src={imageURL}
                            crop={crop}
                            circle={props.circle ? 1 : 0}
                        />
                    )}
                </ImageMask>
                <FileInputElem
                    accept="image/*"
                    {...props}
                    ref={inputRef}
                    disabled={loading}
                    onChange={({
                        target: {
                            validity,
                            files: [file],
                        },
                    }) => {
                        if (!validity.valid) return

                        if (!file) {
                            return reset()
                        }

                        singleUpload({ variables: { file } })

                        var reader = new FileReader()

                        reader.onload = function(e) {
                            setImageURL(e.target.result)
                        }

                        reader.readAsDataURL(file)
                    }}
                />
            </FileLabel>
            <FileActionsRow>
                <Button
                    type="button"
                    css={`
                        margin-right: 0.5rem;
                        width: 110px;
                    `}
                    onClick={() => {
                        inputRef.current.click()
                    }}
                >
                    {imageURL ? 'Change' : 'Upload'}
                </Button>
                {imageURL && value && (
                    <Button
                        type="button"
                        onClick={() => setCropModalOpen(true)}
                    >
                        Edit
                    </Button>
                )}
            </FileActionsRow>
            <CropModal
                circle={props.circle}
                open={cropModalOpen}
                src={imageURL}
                onClose={() => setCropModalOpen(false)}
                fileId={value}
                freeCrop={freeCrop}
                onSave={crop => {
                    setCrop(crop)
                    setCropModalOpen(false)
                }}
            />
        </>
    )
}

export default ({
    error,
    type,
    label,
    comment,
    onChange = () => {},
    ...props
}) => {
    const [dialCode, setDialCode] = useState('')
    const [inputValue, setInputValue] = useState(props.value)

    let InputElem

    switch (type) {
        case 'tel':
            InputElem = PhoneInput
            break
        case 'file':
            InputElem = FileInput
            break
        default:
            InputElem = Input
    }

    const [id] = useState(uuid())

    return (
        <Container>
            <Label htmlFor={id} disabled={props.disabled}>
                {label}
            </Label>
            {type === 'search' && <SearchIcon />}
            <InputContainer>
                {type === 'tel' && (
                    <CountryPicker
                        onChange={e => setDialCode(e.target.value)}
                    />
                )}
                <InputElem
                    autocomplete="off"
                    hasError={error ? 1 : 0}
                    {...props}
                    type={type}
                    onChange={e => {
                        console.log({ dialCode, e })
                        setInputValue(e.target.value)
                        onChange({
                            target: { value: dialCode + e.target.value },
                        })
                    }}
                    value={inputValue}
                    onKeyPress={e => {
                        if (type === 'tel' && !e.key.match(/^\d$/)) {
                            e.preventDefault()
                        }
                    }}
                    id={id}
                />
                {error && error.message && (
                    <Error disabled={props.disabled ? 1 : 0}>
                        {error.message}
                    </Error>
                )}
            </InputContainer>
            {comment && <Comment disabled={props.disabled}>{comment}</Comment>}
        </Container>
    )
}
