import React, { useState } from 'react'

import styled, { keyframes } from 'styled-components'

import Portal from './portal'

import ReactCrop from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'

import { IoIosCloseCircleOutline } from 'react-icons/io'

import Button from './button'

import { useMutation } from '@apollo/react-hooks'

import { SAVE_CROP } from './data-provider'

const Crop = styled(ReactCrop)`
    margin: auto;
`

const CloseButton = styled(IoIosCloseCircleOutline)`
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    display: block;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: ${props => props.theme.gray2};
    animation: ${fadeIn} 0.2s ease;
    overflow: auto;
    width: 100%;
    height: 100%;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 900px;
        margin-top: 10%;
        padding: 2rem;
        height: initial;
    }
`

const Header = styled.header`
    display: flex;
    padding: 0.5rem;
    align-items: center;
`

const Title = styled.h2`
    color: ${props => props.theme.gray1};
    margin: 0;
    font-weight: 500;
    font-size: 1.2rem;
`

const Body = styled.div`
    padding: 0 0.5rem;
`

const Instructions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    pointer-events: none;
`

const CropContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.gray1};
`

export default ({
    open = true,
    title = 'Edit image',
    src = 'http://localhost:3000/5d961535a8a98913f384cce7.jpg',
    onSave,
    onClose,
    fileId,
    circle,
    freeCrop,
}) => {
    const [crop, setCrop] = useState({
        aspect: freeCrop ? 0 : 1,
        unit: '%',
        x: 0,
        y: 0,
        width: 99,
        height: 99,
    })

    const [saveCrop] = useMutation(SAVE_CROP)

    return open ? (
        <Portal id="cropmodal">
            <BackDrop
                onClick={e => {
                    onClose()
                }}
            >
                <Modal
                    onClick={e => {
                        e.stopPropagation()
                    }}
                >
                    <Header>
                        <Title>{title}</Title>
                        <CloseButton onClick={onClose} />
                    </Header>
                    <Body>
                        <CropContainer>
                            <Crop
                                circularCrop={circle}
                                src={src}
                                crop={crop}
                                onChange={(_, percentCrop) =>
                                    percentCrop && setCrop(percentCrop)
                                }
                            />
                            {!crop.width && !crop.height && (
                                <Instructions>Drag to crop</Instructions>
                            )}
                        </CropContainer>
                        <Button
                            css={`
                                display: block;
                                margin-left: auto;
                                margin-top: calc(-4px + 1rem);
                            `}
                            onClick={() => {
                                onSave(crop)
                                saveCrop({ variables: { fileId, crop } })
                            }}
                        >
                            Save image
                        </Button>
                    </Body>
                </Modal>
            </BackDrop>
        </Portal>
    ) : null
}
