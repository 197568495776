import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { Link as GatsbyLink } from 'gatsby'

import { Container } from './styles'

import VerificoLogoImage from '../images/logo.svg'

import { useCurrentUser } from '../hooks'

const Footer = styled.div`
    padding: 0.5rem;
    position: relative;
    min-height: ${props => (props.fixedHeight ? props.theme.footerHeight : 0)};
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px solid ${props => props.theme.gray3};
    background-color: ${props => props.theme.gray2};
    @media print {
        display: none;
    }
`
const Row = styled.div`
    display: flex;
`
const Disclaimer = styled.div`
    font-size: 0.5rem;
    margin: 1rem 0;
    color: ${props => props.theme.gray3};
`

const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
`

const MenuItem = styled.li``

const Link = styled(GatsbyLink)`
    font-size: 0.6rem;
    text-decoration: none;
    color: ${props => props.theme.blue};
`

const Logo = styled(VerificoLogoImage)`
    height: 20px;
    margin-right: 1rem;
`

const menu1 = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'Company',
        link: '/company',
    },
    {
        title: 'Pricing',
        link: '/pricing',
    },
    {
        title: 'FAQs',
        link: '/faqs',
    },
]

const initialMenu2 = [
    {
        title: 'Support',
        link: '/support',
    },
    {
        title: 'Get Verified',
        link: '/verify-your-business',
    },
    {
        title: 'Login',
        link: '/login',
    },
]

export default props => {
    const [menu2, setMenu2] = useState(initialMenu2)

    const [user] = useCurrentUser()

    useEffect(() => {
        if (user) {
            const newMenu = initialMenu2.filter(i => i.link !== '/login')
            setMenu2([
                ...newMenu,
                {
                    title: 'Personal Area',
                    link: '/personal-area',
                },
                {
                    title: 'Logout',
                    link: '/logout',
                },
            ])
        }
    }, [user])

    return (
        <Footer {...props}>
            <Container>
                <Row>
                    <GatsbyLink to="/">
                        <Logo />
                    </GatsbyLink>
                    <Menu>
                        {menu1.map(item => (
                            <MenuItem key={item.title}>
                                <Link to={item.link}>{item.title}</Link>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Menu>
                        {menu2.map(item => (
                            <MenuItem key={item.title}>
                                <Link to={item.link}>{item.title}</Link>
                            </MenuItem>
                        ))}
                    </Menu>
                </Row>

                <Disclaimer>
                    Disclaimer: The business card issued is not a valid legal ID
                    and should not be relied on solely to conduct any financial
                    or business transactions. The involved individuals should
                    carry on their own due diligence prior to conducting
                    business with any party. Verifico,
                    verifiedbussinesscard.com, accept no responsibility for any
                    misuse.
                </Disclaimer>
            </Container>
        </Footer>
    )
}
